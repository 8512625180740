import useLoading from './use-loading';

const shopifyImport = import('./shopify'); // We create a shopify chunk to reduce the bundle size
type ShopifyFetch = Awaited<typeof shopifyImport>['default']['fetch'];

export default function useShopify() {
  return useLoading(async (params: Parameters<ShopifyFetch>[0]) => {
    const shopify = await shopifyImport;

    return shopify.default.fetch(params);
  });
}
