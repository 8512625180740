import Image from 'next/future/image';
import logoIcon from '@/public/icons/buyback/trade-in-logo.svg';

const TradeInLogo = () => (
  <div
    className="flex h-14 w-14 shrink-0 items-center justify-center rounded-xl bg-white"
    // eslint-disable-next-line react/forbid-dom-props
    style={{
      boxShadow: '-1.1px 2.19px 6.58px 0px #07623A40',
    }}
  >
    <div className="h-7 w-7">
      <Image
        alt="Trade-In Logo"
        src={logoIcon}
        height={50}
        width={50}
        quality={100}
        className="h-full w-full"
      />
    </div>
  </div>
);

export default TradeInLogo;
