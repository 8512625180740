import { useState, useCallback, useEffect } from 'react';
import { logger } from './logger';

export default function useLoading<T, R extends Array<any>>(
  fn: (...args: R) => Promise<T>,
  initialLoadingState = false,
): [boolean, (...args: R) => Promise<T>] {
  const [loading, setLoading] = useState(initialLoadingState);
  const wrappedFunction = useCallback(
    (...args: R) => {
      setLoading(true);
      const call = fn(...args);

      return call
        .then((res) => {
          setLoading(false);

          return res;
        })
        .catch((error) => {
          logger.error({ error }, 'Loading error');
          setLoading(false);
          throw error;
        });
    },
    [fn],
  );

  return [loading, wrappedFunction];
}

export const useLoadingEffect = (
  fn: () => Promise<any>,
  deps: React.DependencyList = [],
) => {
  const [loading, wrappedFn] = useLoading(fn, true);

  useEffect(() => {
    void wrappedFn();
  }, deps);

  return loading;
};
